import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { Container, Wrapper, Row, Box } from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Image from "../components/image";

const IndexPage = (props) => (
  <Layout>
    <Seo
      title="Whole Plumbing & Gas Solutions | Auckland, NZ"
      description="Whole Plumbing and Gas Solutions is a plumbing business based in
      Auckland, New Zealand. We specialize in general building work and
      renovations as well as plumbing, gas fitting and drainlaying."
      pathname={props.location.pathname}
    />
    <Container>
      <StaticQuery
        query={graphql`
          query {
            desktop: file(relativePath: { eq: "home/HomeBR.jpg" }) {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        `}
        render={(data) => {
          const imageData = data.desktop.childImageSharp.fluid;
          return (
            <BackgroundImage
              Tag="section"
              fluid={imageData}
              backgroundColor={`#fff`}
            >
              <Wrapper gap={180}>
                <h1
                  className="-textCenter -center -fontLarge"
                  css={{ color: "#fff", maxWidth: "800px" }}
                >
                  Welcome to Whole Plumbing & Gas Solutions
                </h1>
              </Wrapper>
            </BackgroundImage>
          );
        }}
      />
    </Container>
    <Container>
      <Wrapper>
        <h3 className="-textCenter">
          Whole Plumbing and Gas Solutions is a plumbing business based in
          Auckland, New Zealand. We specialize in general building work and
          renovations as well as plumbing, gas fitting and drainlaying. Our team
          of skilled professionals are dedicated to providing quality services
          to our clients. We take pride in our work and ensure that every job is
          done right the first time. We also offer services and help from design
          stage all the way through to completion of the project. Contact us
          today to learn more about how we can help you with your plumbing
          needs.
        </h3>
        <Row alignCenter gap={80}>
          <Box gap={20} size={50}>
            <h4>
              We specialize in renovations, new builds, hot water installations
              and maintenance in both residential & light commercial. We offer
              all services of residential plumbing, gasfitting, drainlaying,
              metal roofing and heating.
            </h4>
            <h4>
              Our promise is to always provide high-quality design and
              craftsmanship and also to be known for our friendly personable
              service. We are committed to providing our customers with the best
              possible service at a fair price.
            </h4>
          </Box>
          <Box size={50}>
            <Image filename="Work.png" alt="Shower & Work by WPGS" />
          </Box>
        </Row>
        <Row alignCenter gap={80} mobileReverse>
          <Box size={50}>
            <Image filename="Druw.png" alt="Druw" />
          </Box>
          <Box gap={20} size={50}>
            <h4>
              Druw started plumbing in 2008 for a small father and son business
              in Wellington where he learned the trade. Then moving onto work
              behind the scenes on films at stone street and eventually moved
              over to London for an OE seeing what trade secrets Europe had to
              offer.
            </h4>
            <h4>
              Druw comes with the experience and passion you can trust. From
              helping you with your vision, designing your dream bathroom or
              kitchen, to helping you in an emergency – WPGS is your local
              Auckland plumber that you can trust.
            </h4>
          </Box>
        </Row>
      </Wrapper>
    </Container>
  </Layout>
);

export default IndexPage;
